<template>
  <div class="flex w-full flex-col">
    <router-link to="/">
      <div class="bg-header-cadastro flex h-32 w-full flex-wrap content-center lg:h-48"></div>
    </router-link>
    <div class="bg-inner-page">
      <div class="mx-auto px-4 py-10 lg:w-2/3 lg:py-24">
        <router-link to="/" class="mb-5 inline-block rounded-full bg-yellow-400 px-5 py-3 font-bold">
          « Voltar
        </router-link>

        <h1 class="uppercase leading-tight">
          <span class="text-7xl">CNH</span>
          <br />
          <span class="text-7xl font-bold">SOCIAL</span>
        </h1>
        <h2 class="mb-8 text-3xl uppercase">
          sua cnh
          <span class="font-bold">gratuita</span>
        </h2>
        <h3 class="mb-4 text-2xl font-bold tracking-wide">Pré-requisitos</h3>
        <div class="text-xl">
          <p class="mb-5">
            Poderão se beneficiar do programa integrantes de famílias comprovadamente de baixa renda domiciliadas e
            residentes no estado do Amazonas. Para os fins deste projeto, são consideradas famílias de baixa renda
            aquelas com renda mensal per capita de até meio salário mínimo ou que possua renda mensal de até dois
            salários mínimos.
          </p>

          <p class="mb-5">
            A renda familiar mensal é a soma dos rendimentos brutos de todos os membros da família, e são excluídos
            desse cálculo rendimentos provenientes de:
          </p>
          <ul class="mb-5 ml-6 list-disc leading-8">
            <li>Programa Bolsa Família</li>
            <li>Programa Nacional de Inclusão do Jovem (Pró-Jovem)</li>
            <li>Auxílio Emergencial Financeiro</li>
            <li>
              Programas de transferência de renda destinados à população atingida por desastres, estado de calamidade
              pública ou situação de emergência
            </li>
            <li>Programas de transferência condicionada de renda, do Estado do Amazonas ou pelo município</li>
          </ul>
          <p class="mb-5">Para se candidatar aos benefícios do programa é necessário:</p>
          <ul class="mb-5 ml-6 list-disc leading-8">
            <li>Ter idade acima de 18 anos na data do requerimento</li>
            <li>
              Estar inscrito, como titular ou dependente, no Cadastro Único para Programas Sociais do Governo Federal
              (CadÚnico)
            </li>
            <li>Saber ler e escrever</li>
            <li>Ser domiciliado no estado do Amazonas há, no mínimo, dois anos, comprovado pelo título eleitoral</li>
            <li>Apresentar comprovante de residência</li>
            <li>Possuir inscrição no CPF</li>
            <li>Possuir carteira de identidade ou equivalente, com foto</li>
            <li>Atender outras condições regulamentadas por decreto estadual</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
